
import {reactive, toRefs, defineComponent, onMounted, provide, ref, inject} from "vue";
import {useRouter, useRoute} from "vue-router";
import XlIcon from "@/components/xl-icon/index.vue";
import {applicationDelete, applicationList} from "@/service/main";
import {ApplicationResponse, LayoutState} from "@/components/layout/interface";
import ModalSaveApplication from "@/components/layout/components/modal-save-application.vue";
import {message, Spin} from "ant-design-vue";
import {useI18n} from "vue-i18n";
import enUS from "ant-design-vue/es/locale/en_US";
import jaJP from "ant-design-vue/es/locale/ja_JP";
export default defineComponent({
    name: "com-layout",
    components: {XlIcon, ModalSaveApplication, ASpin: Spin},
    setup() {
        const {locale, t} = useI18n();
        const router = useRouter();
        const route = useRoute();
        const application = ref<ApplicationResponse | null | undefined>(null);
        const provideLocale:any = inject("locale");
        const state = reactive<LayoutState>({
            applicationList: [],
            options: [],
            pageParam: {
                pageNum: 1,
                pageSize: 999
            },
            applicationId: "",
            saveApplicationVisible: false,
            isLoading: true,
            sliderVisible:true
        });

        onMounted(() => {
            loadApplication();
        });

        const loadApplication = ()=>{
            state.isLoading = true;
            applicationList(state.pageParam)
                .then((res) => {
                    state.applicationList = res.data.rows || [];
                    state.options = state.applicationList.map((item: ApplicationResponse) => {
                        try {
                            item.setting = JSON.parse(item.setting as string || "{}");
                            item.tag = JSON.parse(item.tag || "[]");
                        }
                        catch (e) {
                            item.setting = {};
                            item.tag = [];
                        }
                        return {label: item.name, value: item.id};
                    });
                    if (res.data.rows?.length) {
                        application.value = res.data.rows[0];
                        state.applicationId = res.data.rows[0].id;
                    }
                    state.isLoading = false;
                });
        };


        const applicationChange = (e: string) => {
            application.value = state.applicationList.find(item => item.id === e);
            if(route.name === "page-template-edit"){
                router.push("/templates");
            }
        };
        const pushRouter = (path: string) => {
            router.push(path);
        };
        const saveApplication = () => {
            state.saveApplicationVisible = false;
            loadApplication();
        };

        const deleteApplication = (id:string)=>{
            applicationDelete({
                id:id
            })
                .then(()=>{
                    loadApplication();
                    message.success(t("success"));
                });
        };

        const setLocale = (name:string)=>{
            locale.value = name;
            localStorage.setItem("EMAIL_BUILD_LOCALE", name);
            if(name === "ja_JP"){
                provideLocale.value = jaJP;
            }else{
                provideLocale.value = enUS;
            }
        };
        const logout = ()=>{
            sessionStorage.clear();
            router.replace("/login");
        };

        provide("deleteApplication", deleteApplication);
        provide("application", application);
        return {
            ...toRefs(state),
            pushRouter,
            saveApplication,
            applicationChange,
            setLocale,
            logout
        };
    }
});


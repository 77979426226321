import axios, {AxiosRequestConfig,AxiosResponse}from "axios";
import NProgress from "nprogress"; // Progress 进度条
import "nprogress/nprogress.css";// Progress 进度条样式
import { message } from "ant-design-vue";
import router from "@/router"
const service = axios.create({
    baseURL: "/api",
    withCredentials: true,
    timeout: 100000
});
// 请求拦截器

service.interceptors.request.use((config:AxiosRequestConfig) => {
    NProgress.start();
    if(sessionStorage.getItem("accessToken")){
        config.headers = {
            authorization:sessionStorage.getItem("accessToken")
        };
    }
    config.data = config.data || {};
    return config;
}, error => {
    NProgress.done();
    Promise.reject(error);
});
// respone拦截器
service.interceptors.response.use(
    (result:AxiosResponse) => {
        NProgress.done();
        const res = result.data;

        if(result?.request?.responseType ===  "blob" || result?.request?.responseType ===  "arraybuffer"){
            return result.data;
        }
        if (res.code !== 200) {
            if(res.code === 302){
                message.error("Please Login First");
                sessionStorage.clear();
                router.replace("/login")
            }
            message.error(res.msg);
            return Promise.reject(result.data);
        }
        return res;
    },
    error => {
        NProgress.done();
        message.error("System Error");
        return Promise.reject(error);
    }
);
export default service;

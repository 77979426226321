export default {
    language:"日本語",
    english:"英語",
    japanese:"日本語",
    application:"適用＃テキヨウ＃",
    template:"テンプレート",
    templates:"テンプレート",
    setting:"設定",
    docs:"ドキュメント",
    myTemplates:"マイテンプレート",
    addTemplate:"テンプレートの追加",
    createTemplate:"テンプレートの作成",
    createNow:"今すぐ作成",
    search:"検索けんさく",
    reset:"リセット",
    save:"保存＃ホゾン＃",
    create:"作成",
    edit:"編集",
    delete:"削除",
    searchByName:"名前による検索",
    orderBy:"発注基準",
    design:"せっけい",
    html:"HTML",
    json:"JSON",
    sendMessage:"メッセージを送信",
    applicationSetting:"アプリケーション設定",
    copyOptions:"コピーオプション",
    main:"プライマリ",
    cardMain:"マスターカード",
    border:"辺境",
    fontColor:"フォント色",
    selectedColor:"選択した色",
    createNewGroup:"新規グループの作成",
    createNewTag:"新規マージタグの作成",
    hi:"こんにちは",
    docsTitle:"You are reading the Woodare Email Editor document!",
    docsDownLoad:"How to download",
    property:"Property",
    description:"Description",
    type:"Type",
    default:"Default",
    eventsName:"Events Name",
    arguments:"Arguments",
    pleaseLoginFirst:"先にログインしてください",
    success:"成功",
    copy:"レプリケーション",
    sendEmail:"Eメールの送信",
    login:"登録",
    haveAccount:"アカウントはありませんか？",
    inputEmail:"電子メールを入力してください",
    inputName:"お名前を入力してください",
    inputValue:"値を入力してください",
    signUp:"登録",
    logout:"ログアウト",
    theme:"トピック＃トピック＃",
    applicationName:"アプリケーション名",
    applicationMergeTags:"アプリケーションマージフラグ",
    newApplication:"新規アプリケーション",
    templateName:"テンプレート名",
    email:"Eメール",
    groupName:"グループ名",
    keyName:"鍵名",
    valueName:"値名",
    deleteTemplate:"本当にこのテンプレートを削除しますか？",
    deleteApplication:"本当にこのアプリケーションを削除しますか？",
    deleteGroup:"本当にこのグループを削除しますか？",
    deleteTag:"本当にこのタグを削除しますか？",
    dark:"暗い",
    light:"光",
    formatError:"Format Error",
    downLoadHtml:"HTMLのダウンロード"
}

import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {Dropdown, Menu, Select, Input, Form, Button, Image, Empty, Tabs, Modal,Row,Col,Table,Collapse,Descriptions,Popconfirm,Card} from 'ant-design-vue';
import "@/assets/style/base.less";
import 'ant-design-vue/dist/antd.less';
import "@/assets/style/ant-theme.less";
import "@/permission"
import {jsInstall} from "@/util/jsInstall";
import i18n from '@/assets/in18/index'
jsInstall();
createApp(App).use(store).use(router)
    .use(Dropdown)
    .use(Menu)
    .use(Select)
    .use(Input)
    .use(Form)
    .use(Button)
    .use(Image)
    .use(Empty)
    .use(Tabs)
    .use(Modal)
    .use(Row)
    .use(Col)
    .use(Table)
    .use(Collapse)
    .use(Descriptions)
    .use(Popconfirm)
    .use(i18n)
    .use(Card)
    .mount('#app');


import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/logo.svg'


const _withScopeId = n => (_pushScopeId("data-v-061d263d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout" }
const _hoisted_2 = { class: "head xl-flex xl-stretch" }
const _hoisted_3 = ["width"]
const _hoisted_4 = { class: "xl-expanded" }
const _hoisted_5 = { class: "slider-util xl-flex xl-center xl-justify-start" }
const _hoisted_6 = { class: "head-status xl-flex xl-justify-end" }
const _hoisted_7 = { href: "javascript:;" }
const _hoisted_8 = { href: "javascript:;" }
const _hoisted_9 = { href: "javascript:;" }
const _hoisted_10 = { class: "container xl-flex xl-stretch" }
const _hoisted_11 = { class: "application-select xl-flex xl-center" }
const _hoisted_12 = { class: "menus" }
const _hoisted_13 = { class: "xl-expanded" }
const _hoisted_14 = {
  key: 0,
  class: "xl-flex xl-center",
  style: {"height":"600px"}
}
const _hoisted_15 = {
  key: 2,
  class: "xl-flex xl-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_xl_icon = _resolveComponent("xl-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_modal_save_application = _resolveComponent("modal-save-application")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "head-logo xl-flex",
        style: _normalizeStyle({
                width:_ctx.sliderVisible?'228px':'54px',
                paddingLeft:_ctx.sliderVisible?'35px':'6px',
            })
      }, [
        _createElementVNode("img", {
          src: _imports_0,
          width: _ctx.sliderVisible?120:40,
          alt: ""
        }, null, 8, _hoisted_3)
      ], 4),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createVNode(_component_xl_icon, {
            name: "menu-fold-outlined",
            size: "24",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sliderVisible=!_ctx.sliderVisible))
          }, null, 512), [
            [_vShow, _ctx.sliderVisible]
          ]),
          _withDirectives(_createVNode(_component_xl_icon, {
            name: "menu-unfold-outlined",
            size: "24",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sliderVisible=!_ctx.sliderVisible))
          }, null, 512), [
            [_vShow, !_ctx.sliderVisible]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, {
          class: "head-status-docs",
          to: "/document"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("docs")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_dropdown, { placement: "bottom" }, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_menu, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu_item, {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setLocale('enUS')))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t("english")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_menu_item, {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setLocale('ja_JP')))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t("japanese")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "head-status-language",
              onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t("language")) + " ", 1),
              _createVNode(_component_xl_icon, { name: "DownOutlined" })
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_dropdown, { placement: "bottom" }, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_menu, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu_item, { onClick: _ctx.logout }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.$t("logout")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "head-status-language",
              onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createElementVNode("div", {
                class: "head-status-user xl-flex xl-center",
                onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
              }, _toDisplayString(_ctx.$t("hi")), 1)
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", {
        class: "slider",
        style: _normalizeStyle({
                width:_ctx.sliderVisible?'228px':'54px'
            })
      }, [
        _withDirectives(_createElementVNode("div", _hoisted_11, [
          _createVNode(_component_a_select, {
            value: _ctx.applicationId,
            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.applicationId) = $event)),
            style: {"width":"100%"},
            "show-search": "",
            placeholder: "Application",
            options: _ctx.options,
            onChange: _ctx.applicationChange
          }, null, 8, ["value", "options", "onChange"]),
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.saveApplicationVisible = true))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_xl_icon, { name: "plus-outlined" })
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, _ctx.sliderVisible]
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_router_link, {
            class: "menu-item",
            to: "/templates"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_xl_icon, { name: "form-outlined" }),
              _createTextVNode(),
              _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.$t("templates")), 513), [
                [_vShow, _ctx.sliderVisible]
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "menu-item",
            to: "/setting"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_xl_icon, { name: "SettingOutlined" }),
              _createTextVNode(),
              _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.$t("setting")), 513), [
                [_vShow, _ctx.sliderVisible]
              ])
            ]),
            _: 1
          })
        ])
      ], 4),
      _createElementVNode("div", _hoisted_13, [
        (_ctx.applicationList.length === 0 && !_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_component_a_empty, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.saveApplicationVisible = true))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("createNow")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : (!_ctx.isLoading && _ctx.applicationList.length)
            ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_a_spin, { size: "large" })
              ]))
      ]),
      _createVNode(_component_modal_save_application, {
        visible: _ctx.saveApplicationVisible,
        "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.saveApplicationVisible) = $event)),
        onHandleOk: _ctx.saveApplication,
        onCancel: _cache[11] || (_cache[11] = ($event: any) => (_ctx.saveApplicationVisible= false))
      }, null, 8, ["visible", "onHandleOk"])
    ])
  ]))
}
import router from "@/router";
import { message } from "ant-design-vue";
const whiteList = ["/login","/register"];
import {RouteLocationNormalized,NavigationGuardNext} from "vue-router";

router.beforeEach((to:RouteLocationNormalized, from:RouteLocationNormalized, next:NavigationGuardNext)=>{
    if(whiteList.indexOf(to.path) === -1){
        if(sessionStorage.getItem("accessToken")){
            next();
        }else{
            message.error("Please Login First");
            next({ path:"/login", replace: true });
        }
    }else{
        next();
    }
});

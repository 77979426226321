export default {
    language:"English",
    english:"English",
    japanese:"Japanese",
    application:"Application",
    template:"Template",
    templates:"Templates",
    setting:"Setting",
    docs:"Docs",
    myTemplates:"My Templates",
    addTemplate:"Add Template",
    createTemplate:"Create Templates",
    createNow:"Create now",
    search:"Search",
    reset:"Reset",
    save:"Save",
    create:"Create",
    edit:"Edit",
    delete:"Delete",
    searchByName:"Search By Name",
    orderBy:"Order By",
    design:"Design",
    html:"HTML",
    json:"JSON",
    sendMessage:"Send Message",
    applicationSetting:"Application Setting",
    copyOptions:"Copy Options",
    main:"Main",
    cardMain:"Card Main",
    border:"Border",
    fontColor:"Font Color",
    selectedColor:"Selected Color",
    createNewGroup:"Create New Group",
    createNewTag:"Create New Merge Tag",
    hi:"Hi",
    docsTitle:"You are reading the Woodare Email Editor document!",
    docsDownLoad:"How to download",
    property:"Property",
    description:"Description",
    type:"Type",
    default:"Default",
    eventsName:"Events Name",
    arguments:"Arguments",
    pleaseLoginFirst:"Please login first",
    success:"success",
    copy:"copy",
    sendEmail:"send Email",
    login:"LOGIN",
    haveAccount:"Don't have an account?",
    inputEmail:"Please input email",
    inputName:"Please input name",
    inputValue:"Please input value",
    signUp:"Sign Up",
    logout:"Logout",
    theme:"Theme",
    applicationName:"Application Name",
    applicationMergeTags:"Application Merge Tags",
    newApplication:"New Application",
    templateName:"Template Name",
    email:"Email",
    groupName:"Group Name",
    keyName:"Key Name",
    valueName:"Value Name",
    deleteTemplate:"Are you sure delete this template?",
    deleteApplication:"Are you sure delete this application?",
    deleteGroup:"Are you sure delete this Group?",
    deleteTag:"Are you sure delete this Tag?",
    dark:"Dark",
    light:"Light",
    formatError:"Format Error",
    downLoadHtml:"Download HTML"
}

import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router';
import Layout from "@/components/layout/index.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/',
                redirect:"/templates"
            },
            {
                path: '/templates',
                name: 'page-templates',
                component: () => import(/* webpackChunkName: "templates" */ '../views/templates/layout.vue'),
                children:[
                    {
                        path: '/templates',
                        name: 'page-template',
                        component: () => import(/* webpackChunkName: "templates" */ '../views/templates/index.vue'),
                    },
                    {
                        path: '/templates/edit/:id',
                        name: 'page-template-edit',
                        component: () => import(/* webpackChunkName: "create" */ '../views/create/index.vue')
                    }
                ]
            },
            {
                path: '/document',
                name: 'page-document',
                component: () => import(/* webpackChunkName: "document" */ '../views/document/index.vue')
            },
            {
                path: '/setting',
                name: 'page-setting',
                component: () => import(/* webpackChunkName: "setting" */ '../views/setting/index.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../views/register/index.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
});

export default router


import {ConfigProvider} from "ant-design-vue";
import {defineComponent, ref, provide} from "vue";
import enUS from "ant-design-vue/es/locale/en_US";
import jaJP from "ant-design-vue/es/locale/ja_JP";
export default defineComponent({
    name:"App",
    components:{
        AConfigProvider:ConfigProvider
    },
    setup(){
        const localeStr = localStorage.getItem("EMAIL_BUILD_LOCALE");
        const locale = ref(enUS);

        if(localeStr === "ja_JP"){
            locale.value = jaJP;
        }else{
            locale.value = enUS;
        }

        provide("locale", locale);

        return {
            locale
        };
    }
});

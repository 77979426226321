import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, _mergeProps(_toHandlers(_ctx.$attrs), {
    title: _ctx.$t('newApplication'),
    onOk: _ctx.onsubmit
  }), {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        model: _ctx.queryParams,
        name: "basic",
        autocomplete: "off",
        ref: "formRefs",
        rules: _ctx.rules,
        layout: "vertical"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            name: "name",
            label: _ctx.$t('applicationName')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                allowClear: "",
                placeholder: _ctx.$t('applicationName'),
                value: _ctx.queryParams.name,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryParams.name) = $event))
              }, null, 8, ["placeholder", "value"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 16, ["title", "onOk"]))
}
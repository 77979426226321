import enUs from "@/assets/in18/en-us";
import jp from "@/assets/in18/jp";
import {createI18n} from 'vue-i18n'
type MessageSchema = typeof enUs
export default createI18n<[MessageSchema], 'enUS' | 'ja_JP'>({
    globalInjection:true,
    locale:localStorage.getItem("EMAIL_BUILD_LOCALE") || "enUS",
    legacy: false, // you must specify 'legacy: false' option
    messages: {
        'enUS': enUs,
        'ja_JP': jp
    }
});


import {saveApplication} from "@/service/main";
import {message} from "ant-design-vue";
import {defineComponent, reactive, toRefs, ref} from "vue";
import {useI18n} from "vue-i18n";

export default defineComponent({
    name: "modal-save-application",
    emits:["handleOk"],
    setup(props, context) {
        const { t } = useI18n();
        const formRefs =ref();
        const state = reactive({
            queryParams:{
                name:""
            },
            rules:{
                name:[{ required: true, message: t("inputName") }],
            }
        });
        const onsubmit = ()=>{
            formRefs.value.validate()
                .then(()=>{
                    saveApplication(state.queryParams)
                        .then(()=>{
                            message.success("success!");
                            context.emit("handleOk");
                            formRefs.value.resetFields();
                        });
                });
        };

        return {
            formRefs,
            ...toRefs(state),
            onsubmit
        };
    }
});

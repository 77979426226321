function deepClone(data:any) {
    const map:any = data instanceof Array? []:{};

    for(const key in data){
        if(data[key] && typeof data[key] === "object"){
            map[key] = deepClone(data[key]);
        }else{
            map[key] = data[key];
        }
    }

    return map;
}


export function jsInstall() {
    (Object as any).deepClone = deepClone;
}

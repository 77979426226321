
import * as icons from "@ant-design/icons-vue";
import {defineComponent} from "vue";
export default defineComponent({
    name:"xl-icon",
    props: {
        name: { type: String, required: true },
        color: { type: String, required: false },
        size: [Number, String]
    },
    components:icons
});



import {ApplicationFormState, ApplicationResponse} from "@/components/layout/interface";
import {RequestPage, RequestPageResponse, RequestResponse} from "@/interface/request";
import request from "@/service/request";
import {TemplateFormState, TemplateResponse} from "@/views/templates/interface";

export function applicationList(params:RequestPage):Promise<RequestResponse<RequestPageResponse<ApplicationResponse>>> {
    return request({
        url:"/client/application/list",
        method:"POST",
        data:{
            param:params
        }
    })
}

export function applicationUpdate(params:Partial<ApplicationResponse>):Promise<RequestResponse<number>> {
    return request({
        url:"/client/application/update",
        method:"POST",
        data:{
            param:params
        }
    })
}




export function saveApplication(params:ApplicationFormState):Promise<RequestResponse<number>> {
    return request({
        url:"/client/application/add",
        method:"POST",
        data:{
            param:params
        }
    })
}

export function applicationDelete(params:Partial<ApplicationResponse>):Promise<RequestResponse<number>> {
    return request({
        url:"/client/application/delete",
        method:"POST",
        data:{
            param:params
        }
    })
}


export function templateList(params:RequestPage):Promise<RequestResponse<RequestPageResponse<TemplateResponse>>> {
    return request({
        url:"/client/template/list",
        method:"POST",
        data:{
            param:params
        }
    })
}

export function templateAdd(params:Partial<TemplateFormState>):Promise<RequestResponse<string>> {
    return request({
        url:"/client/template/add",
        method:"POST",
        data:{
            param:params
        }
    })
}


export function templateDelete(params:Partial<TemplateFormState>):Promise<RequestResponse<number>> {
    return request({
        url:"/client/template/delete",
        method:"POST",
        data:{
            param:params
        }
    })
}

export function templateEdit(params:Partial<TemplateFormState>):Promise<RequestResponse<string>> {
    return request({
        url:"/client/template/update",
        method:"POST",
        data:{
            param:params
        }
    })
}

export function templateDetail(params:Partial<TemplateResponse>):Promise<RequestResponse<TemplateResponse>> {
    return request({
        url:"/client/template/detail",
        method:"POST",
        data:{
            param:params
        }
    })
}


export function fileUpload(params:any):Promise<any> {
    return request({
        url:"https://email.t192.woodare.com/dev-api/file/upload",
        method:"POST",
        data:params
    })
}

export function emailSend(params:any):Promise<any> {
    return request({
        url:"/email/send",
        method:"POST",
        data:params
    })
}

